export default function Sobre () {
    return (
        <div align="center">
            <h1>- SOBRE O APP -</h1>
            <hr/>
            

            <div>
                <p>para críticas ou sugestões, envie um email para
                    <br/>
                    <a>contato@iwtraining.tec.br</a>
                </p>
            </div>
            
        </div>
    )
}