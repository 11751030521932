export default function Table() {
  return (
    <table border="1" width="100%">
      <tr>
        <th>Coach</th>
        <th>Horario</th>
      </tr>
      <tr>
        <th>Vitor</th>
        <th>06:00 as 07:00</th>
      </tr>

      <tr>
        <th>Vitor</th>
        <th>06:00 as 07:00</th>
      </tr>
    </table>
  )
}
